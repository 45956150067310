const React = require("react");
const Sentry = require("@sentry/gatsby");
const { useEffect } = React;

const Monitoring = ({ element }) => {
  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setTag(
        "environment",
        ["www.nwboxed.com", "nwboxed.com"].includes(window.location.host)
          ? "PROD"
          : "TEST"
      );
    });
  }, []);

  return <>{element}</>;
};

exports.wrapRootElement = ({ element }) => <Monitoring element={element} />;
